import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import App from './App.vue'
import axios from 'axios'
import router from '@/router/index.js'
// import "../public/js/TweenMax.min.js";

Vue.use(ElementUI);
Vue.use(ViewUI);

Vue.config.productionTip = false

let echarts = require('echarts')

let BASE_URL_SERVER = "https://apifile.safesrcc.com";
let STATIC_URL_SERVER = "https://file.safesrcc.com/";


Vue.prototype.$echarts = echarts;
axios.defaults.withCredentials = false;
Vue.prototype.$axios = axios;
Vue.prototype.$url = BASE_URL_SERVER;
Vue.prototype.$static_url = STATIC_URL_SERVER;

Vue.prototype.$fileUrlFac = function(url,pid){
  if(url&&url.indexOf('http')===-1){
    return STATIC_URL_SERVER + url +'?id=' + pid;
  }else{
      return url;
    }
  
}

Vue.prototype.$fileUrlFacWithoutRight = function(url){
  if(url.indexOf('http')===-1){
  return STATIC_URL_SERVER + url;}
  else{
    return url;
  }
}


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

export {BASE_URL_SERVER}


