<template>
    <div id="app">
      <router-view></router-view>
      
      <BackTop></BackTop>

    </div>
</template>

<script>
  export default {
    name: 'app',
  }
</script>

<style>
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}


</style>