//基础框架
const manageFrame = () => import('@/components/ManageFrame.vue');
const searchResult = () => import('@/components/Common/SearchResult.vue');

//主页
const mainIndex = () => import('@/components/MainIndex.vue');

//登录及账户管理
const loginBox = () => import('@/components/Account/Login.vue');

//后台管理
const menuBar = () => import('@/components/MenuBar.vue');
const roleManage = () => import('@/components/Account/RoleManage.vue');

//标准及条目
const standardManage = () => import('@/components/Rules/StandardManage.vue');
const majorManage = () => import('@/components/Rules/MajorManage.vue');
const clauseManage = () => import('@/components/Rules/ClauseManage.vue');
const clauseRelation = () => import('@/components/Rules/ClauseRelation.vue');

//项目
const projectManage = () => import('@/components/Project/ProjectManage.vue');
const projectContent = () => import('@/components/Project/ProjectContent.vue');
const projectProgress = () => import('@/components/Project/ProjectProgress.vue');

//文件
const fileUpload = () => import('@/components/File/FileUpload.vue');
const fileVerify = () => import('@/components/File/FileVerify.vue');
const fileConfirm = () => import('@/components/File/FileConfirm.vue');
const fileEdit = () => import('@/components/File/FileEdit.vue');


export default [
    //文件
    {
        path:"/file",
        name:"file",
        component:manageFrame,
        children:[
            {
                path:"upload",
                name:"fileUpload",
                component:fileUpload,
            },
            {
                path:"edit/:fileId",
                name:"fileEdit",
                component:fileEdit,
                props:true
            },
            {
                path:"verify",
                name:"fileVerify",
                component:fileVerify,
            },
            {
                path:"confirm",
                name:"fileConfirm",
                component:fileConfirm,
            },
        ]
    },
    //注册和登录
    {
        path:"/login",
        name:"login",
        component:loginBox,
    },
    //主页
    {
        path:"/index",
        name:"index",
        component:mainIndex,
    },
    
    //主页搜索
    {
        path:"/search",
        name:"search",
        component:manageFrame,
        children:[
            {
                path:"search_result",
                name:"search_result",
                component:searchResult,
            },
        ]
    },

    //数据后台管理
    {
        path:"/manage",
        name:'manage',
        component:manageFrame,
        children:[
            {
                path:'roleManage',
                name:'roleManage',
                components:{
                    default:roleManage,
                    leftBar:menuBar,
                }
            },
            {
                path:"standardManage",
                name:"standardManage",
                components:{
                    default:standardManage,
                    leftBar:menuBar,
                }
            },
            {
                path:"majorManage",
                name:"majorManage",
                components:{
                    default:majorManage,
                    leftBar:menuBar,
                }
            },
            {
                path:"clauseManage/:standardId",
                name:"clauseManage",
                components:{
                    default:clauseManage,
                    leftBar:menuBar,
                },
                props:{
                    default:true,
                    leftBar:false,
                }
            },
            {
                path:"projectManage",
                name:"projectManage",
                components:{
                    default:projectManage,
                    leftBar:menuBar,
                }
            },
            {
                path:"clauseRelation",
                name:"clauseRelation",
                components:{
                    default:clauseRelation,
                    leftBar:menuBar,
                }
            },
            {
                path:"projectContent/:projectId",
                name:"projectContent",
                components:{
                    default:projectContent,
                    leftBar:menuBar,
                },
                props:{
                    default:true,
                    leftBar:false,
                }
                
            },
            {
                path:"projectProgress/:projectId",
                name:"projectProgress",
                components:{
                    default:projectProgress,
                    leftBar:menuBar,
                },
                props:{
                    default:true,
                    leftBar:false,
                }
                
            },

        ]

    },
]